<template>
    <dv-border-box-13
            style="height: 100%;position: relative;display: flex;flex-direction: column;justify-content: space-between;margin-bottom: 3px">
        <div style="display: flex;justify-content: center;color: #fff;width: 100%" v-if="!hide">
            <dv-decoration-1 class="icon" style="width: 200px" :style="{height:is_big?'50px':'30px'}"/>
            <h2 style="padding: 10px 0" v-if="is_big">{{ title }}</h2>
            <h3 style="padding: 10px 0" v-else>{{ title }}</h3>
        </div>
        <div style="width: 100%;padding: 2px"
             :style="{height:hide?'100%':'calc(100% - 50px)' }">
            <slot></slot>
        </div>
    </dv-border-box-13>
</template>

<script>
export default {
	name: "data_box",
	props: ['title', 'is_big', 'hide']
}
</script>

<style scoped>
.icon {
    width: 20%;
    height: 5%;
    position: absolute;
    top: 10px;
    left: 50%;
    transform: translateX(-50%)
}
</style>
